import { navigation } from "@context/server";
import { useStore } from "@nanostores/react";
import { useEffect } from "react";
import { isMobileMenuOpen } from "src/context/menuStore";
const currentYear = new Date().getFullYear();
export const MobileMenu = () => {
    const _isMenuOpen = useStore(isMobileMenuOpen);
    useEffect(() => {
        return () => isMobileMenuOpen.set(false)
    }, [])
    return (
        <>
            <div style={{opacity: _isMenuOpen ? 1 : 0, zIndex: _isMenuOpen ? 30 : -1}} className="mobile-menu | fixed inset-0 z-30 grid gap-x-3 transition-all duration-300">
                <nav className="mobile-nav | self-center">
                    <ul className="flex flex-col gap-y-9">
                        {navigation.concat([{href:"/", name: "home"}]).map((nav, idx) => {
                            return (
                                <li>
                                    <a
                                        aria-current="page"
                                        href={nav.href}
                                        className="router-link-active router-link-exact-active flex items-end justify-between gap-x-4"
                                    >
                                        <span className="text-body-5">{idx+1}</span>
                                        <span className="ml-auto block h-1 w-1 self-center bg-current"></span>
                                        <span className="display-5 uppercase">{nav.name}</span>
                                    </a>
                                </li>                                
                            )
                        })}
                    </ul>
                </nav>
                <div
                    className="mobile-title | flex flex-col gap-y-3 opacity-30"
                    aria-hidden="true"
                    data-v-79b42b20=""
                >
                    <span className="label-9">©{currentYear}</span>
                    <span className="display-6 | inline-flex flex-col">
                        <span>BagsByData</span>
                    </span>
                </div>
                <div className="mobile-thing | label-8 | self-end justify-self-end">
                    <span className="opacity-30" data-v-79b42b20="">
                        B/22
                    </span>
                </div>
            </div>
        </>
    );
};